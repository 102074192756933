'use client';

import { usePostHog } from 'posthog-js/react';

export type ErrorProps = {
  error: Error;
};

export default function Error({ error }: ErrorProps) {
  const posthog = usePostHog();

  posthog?.capture('$exception', {
    message: error.message,
    stack: error.stack,
  });

  return (
    <div className="min-h-screen flex flex-grow items-center justify-center bg-gray-50">
      <div className="rounded-lg bg-white p-8 text-center shadow-xl">
        <h1 className="mb-4 text-4xl font-bold text-gray-900">Oops!</h1>
        <p className="text-gray-600">Algo deu errado. Por favor, tente novamente mais tarde.</p>
      </div>
    </div>
  );
}
